<template>
    <div>
        <div class="font-bold text-2xl">Ambientes</div>
        <div class="text-bluegray-700">Esses dados são necessários para automatizar sua agenda</div>

        <TabelaAmbientes ref="tabelaAmbientes" v-model:formData="configuracoesAmbiente" :procedimentos="procedimentos" />

        <div class="flex flex-column gap-2">
            <label for="tempo" class="text-bluegray-600">Tempo para PCD, retorno ao trabalho e consulta (minutos)</label>
            <InputNumber
                v-model="configuracoesAmbiente.tempoPCDRetornoAoTrabalhoConsulta"
                class="w-full"
                id="tempo"
                :class="{ 'p-invalid': v$.configuracoesAmbiente.tempoPCDRetornoAoTrabalhoConsulta.$invalid }"
            />
            <small v-if="v$.configuracoesAmbiente.tempoPCDRetornoAoTrabalhoConsulta.$invalid" class="p-error w-full">
                {{ v$.configuracoesAmbiente.tempoPCDRetornoAoTrabalhoConsulta.required.$message }}
            </small>
        </div>
    </div>
</template>

<script>
import TabelaAmbientes from './TabelaAmbientes.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    emits: ['update:formData'],
    components: { TabelaAmbientes },
    props: {
        formData: {
            type: Object,
            required: true
        },
        procedimentos: {
            type: Array,
            required: true
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    computed: {
        configuracoesAmbiente: {
            get() {
                return this.formData;
            },
            set(value) {
                this.$emit('update:formData', value);
            }
        }
    },
    methods: {
        validar() {
            return this.$refs.tabelaAmbientes.validar() && this.v$.$validate();
        }
    },
    validations() {
        return {
            configuracoesAmbiente: {
                tempoPCDRetornoAoTrabalhoConsulta: {
                    required: helpers.withMessage('Informe o tempo de PCD retorno ao trabalho consulta', required),
                    $lazy: true
                }
            }
        };
    }
};
</script>
