<template>
    <div>
        <div class="font-bold text-2xl">Dados do SOC</div>
        <div class="text-bluegray-700 font-medium">Esses dados são necessários para automatizar sua agenda</div>
        <div class="grid mt-2">
            <div v-for="(value, key) in form" :key="key" :class="{ 'p-invalid': v$.form?.[key]?.$invalid, [definirClasse(key)]: true }">
                <template v-if="key !== 'idAgenda'">
                    <label class="label text-bluegray-500" :for="key">{{ mapaLabels[key] }}</label>
                    <InputText
                        :id="key"
                        autocomplete="off"
                        class="w-full p-4"
                        v-model.trim="form[key]"
                        :class="{ 'p-invalid': v$.form[key].$invalid }"
                    />
                    <small v-if="v$.form[key].$invalid || v$.form[key].$pending.$response" class="p-error w-full">
                        {{ v$.form[key].required.$message }}
                    </small>
                </template>
            </div>
        </div>
        <div class="mt-4">
            <div class="font-bold text-2xl">Onde conseguir essas informações?</div>
            <Button
                label="Baixar manual de informações"
                icon="pi pi-download"
                class="p-button p-button-text p-button-rounded download-btn mt-3"
                @click="downloadManual"
            />
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';

export default {
    emits: ['update:formData'],
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    data() {
        return {
            mapaLabels: {
                username: 'Username (Usuário SOC)',
                codigoEmpresaPrincipal: 'Código da empresa principal',
                chaveAcesso: 'Chave de acesso/password',
                codigoExportaDados: 'Código exporta dados das agendas',
                chaveAcessoExportaDados: 'Chave exporta dados das agendas',
                codigoUsuarioSoc: 'Código usuário SOC',
                codigoResponsavel: 'Código responsável'
            }
        };
    },
    computed: {
        form: {
            get() {
                return this.formData;
            },
            set(value) {
                this.$emit('update:formData', value);
            }
        }
    },
    watch: {
        form: {
            handler() {
                this.$emit('update:formData', this.form);
            },
            deep: true
        }
    },
    methods: {
        validar() {
            return this.v$.$validate();
        },
        async downloadManual() {
            const pdfFileName = 'Manual-SOC.pdf';
            const downloadURL = `${process.env.VUE_APP_API_BASE_URL}manuais/${pdfFileName}`;
            await axios({
                method: 'get',
                url: downloadURL,
                responseType: 'blob'
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = pdfFileName;
                link.click();
            });
        },
        definirClasse(key) {
            if (key === 'idAgenda') return;
            const index = Object.keys(this.form).indexOf(key);
            if (this.form.length % 2 === 0) {
                return 'md:col-6 col-12';
            }
            return index === 0 ? 'col-12' : 'md:col-6 col-12';
        }
    },
    validations() {
        return {
            form: {
                username: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                codigoEmpresaPrincipal: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                chaveAcesso: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                codigoExportaDados: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                chaveAcessoExportaDados: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                codigoUsuarioSoc: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true },
                codigoResponsavel: { required: helpers.withMessage('Campo Obrigatório', required), $lazy: true }
            }
        };
    }
};
</script>

<style lang="scss" scoped>
.download-btn {
    font-size: 1.2rem;
    font-family: Poppins-Medium, sans-serif;
    color: #3f51b5;
}
</style>
