<template>
    <div class="page-cnt w-full h-full bg-white flex flex-column align-items-center gap-6">
        <div class="header w-full">
            <span v-for="n in 3" :key="n" :class="`item-${n}`" />
        </div>

        <div class="p-4 pt-0 w-full h-full">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reverseBar: { type: Boolean, default: false }
    },
    computed: {
        proporcaoBarras() {
            return this.reverseBar ? '3fr 2fr 1fr' : '1fr 2fr 3fr';
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-claro: #3ba7e3;
$rosa-medio: #364de7;
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

.page-cnt {
    min-height: 100vh;
    font-family: Poppins-Regular, sans-serif;

    .header {
        height: 4px;
        display: grid;
        grid-template-columns: v-bind(proporcaoBarras);

        .item-1 {
            background-color: $rosa-claro;
        }

        .item-2 {
            background-color: $rosa-medio;
        }

        .item-3 {
            background-color: $rosa-primario;
        }
    }
}
</style>
