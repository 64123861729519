<template>
    <div class="container">
        <div class="border-round-2xl mt-3 p-6 pb-4 box">
            <div class="flex justify-content-center gap-6">
                <div
                    class="flex gap-3 align-items-center font-bold passo-container select-none md:w-14rem"
                    v-for="(passo, index) in passos"
                    :key="passo.titulo"
                    :class="{ ativo: passo.ativo, checked: indexPassoAtual > index, 'cursor-pointer': index < indexPassoAtual }"
                    @click="selecionarPasso(index)"
                >
                    <span v-if="indexPassoAtual > index" class="pi pi-check indicador-passo checked" />
                    <span v-else class="indicador-passo text-sm">
                        {{ index + 1 }}
                    </span>

                    <span class="passo hidden md:block">
                        {{ passo.titulo }}
                    </span>
                </div>
            </div>

            <component
                :is="passoAtual.componente"
                v-model:formData="form[passoAtual.chave]"
                :procedimentos="form.procedimentos"
                class="mt-5 form-container"
                :ref="passoAtual.chave"
            />
        </div>
        <div class="btn-container">
            <Button
                v-if="passoAtual !== passos.at(0)"
                class="p-button-text p-button-rounded btn btn-anterior"
                label="Anterior"
                @click="voltarPasso"
            />
            <Button
                class="p-button-rounded btn btn-proximo"
                :label="passoAtual !== passos.at(-1) ? 'Próximo' : 'Salvar cadastro'"
                @click="proximoOuSalvar"
            />
        </div>
    </div>
    <DialogValidacaoSoc ref="dialogValidacaoSoc" :formData="form.dadosSoc" />
    <DialogConfirmarSalvamento ref="dialogConfirmacao" :formData="form" @onSalvarPrestador="finalizarCadastro" />
    <DialogProcedimentosNaoRegistrados ref="dialogProcedimentosNaoRegistrados" />
    <Toast />
</template>

<script>
import DadosSoc from './DadosSoc.vue';
import Procedimentos from './Procedimentos.vue';
import Ambiente from './Ambiente.vue';
import DialogValidacaoSoc from './DialogValidacaoSoc.vue';
import DialogConfirmarSalvamento from './DialogConfirmarSalvamento.vue';
import DialogProcedimentosNaoRegistrados from './DialogProcedimentosNaoRegistrados.vue';
import { logoutPrestador } from '@/services/auth';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';

export default {
    components: {
        DadosSoc,
        Procedimentos,
        Ambiente,
        DialogValidacaoSoc,
        DialogConfirmarSalvamento,
        DialogProcedimentosNaoRegistrados
    },
    data() {
        return {
            passos: [
                { ativo: true, titulo: 'Dados do SOC', componente: 'DadosSoc', chave: 'dadosSoc' },
                { ativo: false, titulo: 'Procedimentos', componente: 'Procedimentos', chave: 'procedimentos' },
                { ativo: false, titulo: 'Ambiente', componente: 'Ambiente', chave: 'ambiente' }
            ],
            form: {
                dadosSoc: {
                    username: '',
                    codigoEmpresaPrincipal: '',
                    chaveAcesso: '',
                    codigoExportaDados: '',
                    chaveAcessoExportaDados: '',
                    codigoUsuarioSoc: '',
                    codigoResponsavel: ''
                },
                procedimentos: [],
                ambiente: {
                    ambientes: [
                        {
                            nome: '',
                            exames: [],
                            codigoAgendaSoc: '',
                            tempoAtendimento: 0
                        }
                    ],
                    tempoPCDRetornoAoTrabalhoConsulta: 0
                }
            }
        };
    },
    watch: {
        'form.procedimentos': {
            handler(val, oldVal) {
                const itensRemovidos = oldVal.filter((item) => !val.some((v) => v.descricao === item.descricao)).map((item) => item.descricao);

                if (itensRemovidos.length) {
                    this.form.ambiente.ambientes.forEach((ambiente) => {
                        ambiente.exames = ambiente.exames.filter((e) => !itensRemovidos.includes(e));
                    });
                }
            },
            deep: true
        }
    },
    computed: {
        passoAtual() {
            return this.passos.find((passo) => passo.ativo);
        },
        gridTemplateColumns() {
            return this.passoAtual === this.passos.at(0) ? '1fr' : '1fr 1fr';
        },
        indexPassoAtual() {
            return this.passos.findIndex((passo) => passo.ativo);
        }
    },
    methods: {
        selecionarPasso(index) {
            if (index < this.indexPassoAtual) {
                this.passos.forEach((passo) => (passo.ativo = false));
                this.passos[index].ativo = true;
            }
        },
        voltarPasso() {
            const indexPassoAtual = this.passos.findIndex((passo) => passo.ativo);
            this.passos[indexPassoAtual].ativo = false;
            this.passos[indexPassoAtual - 1].ativo = true;
        },
        avancarPasso(indexPassoAtual) {
            this.passos[indexPassoAtual].ativo = false;
            this.passos[indexPassoAtual + 1].ativo = true;
        },
        async proximoOuSalvar() {
            const indexPassoAtual = this.passos.findIndex((passo) => passo.ativo);
            if (!(await this.$refs[this.passoAtual.chave].validar())) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Preencha todos os campos obrigatórios', life: 3000 });
                return;
            }
            if (indexPassoAtual === this.passos.length - 1) {
                const todosProcedimentosAlocados = this.verificarTodosProcedimentosAlocados();
                if (!todosProcedimentosAlocados) {
                    this.$refs.dialogProcedimentosNaoRegistrados.openDialog();
                } else {
                    this.$refs.dialogConfirmacao.openDialog();
                }
            } else {
                if (indexPassoAtual === 0) {
                    const podeAvancar = await this.validarDadosSoc();
                    if (podeAvancar) {
                        this.avancarPasso(indexPassoAtual);
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Ocorreu um erro ao validar seus dados. Por favor, tente novamente.',
                            detail: 'Não foi possível validar os dados do SOC. Verifique se os dados estão corretos e tente novamente.',
                            life: 3000
                        });
                    }
                } else {
                    this.avancarPasso(indexPassoAtual);
                }
            }
        },
        async validarDadosSoc() {
            return await this.$refs.dialogValidacaoSoc.openDialogAndValidate();
        },
        async finalizarCadastro() {
            logoutPrestador();
            await this.$router.push('/cadastro-prestador/login?finalizado=true');
        },
        verificarTodosProcedimentosAlocados() {
            const procedimentos = this.form.procedimentos.map((p) => p.descricao);
            const exames = this.form.ambiente.ambientes.flatMap((a) => a.exames);
            return procedimentos.every((p) => exames.includes(p));
        }
    },
    async created() {
        try {
            const prestadorToken = getPrestadorToken();
            const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
            const { data } = await getClientBase(authToken).get('/cadastro-prestador/obter-cadastro-anterior');
            if (data) {
                this.form = data;
            }
        } catch (error) {
            if (error.response.data.statusCode === 401) {
                logoutPrestador();
                await this.$router.push('/cadastro-prestador/');
            }
            this.$toast.add({
                severity: 'error',
                summary: 'Erro ao recuperar dados de cadastro anterior',
                detail: error.response.data.message,
                life: 3000
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

.container {
    font-family: Poppins-Regular, sans-serif;
    min-height: 580px;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr fit-content(100%);
    gap: 16px;
    width: 100%;
    max-width: 800px;

    .box {
        width: 100%;
        background: #f1f5f9;

        .passo-container {
            padding: 16px;
            border-radius: 8px;
            border: 2px solid #94a3b8;
            display: flex;
            opacity: 0.4;

            &.checked {
                opacity: 1;
                border-color: #94a3b866;
            }

            .indicador-passo {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 2px solid #64748b;
                display: flex;
                justify-content: center;
                align-items: center;

                &.checked {
                    background: #4caf50;
                    border-color: #4caf50;
                    color: #fff;
                    opacity: 1;
                }
            }

            .passo {
                font-size: 12px;
            }

            &.ativo {
                opacity: 1;

                .indicador-passo {
                    border-color: #3f51b5;
                }
            }
        }

        .form-container {
            font-family: Poppins-Regular, sans-serif;
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 4px;
        }
    }

    .btn-container {
        display: grid;
        grid-template-columns: v-bind(gridTemplateColumns);

        .btn {
            min-width: 85px;
            font-weight: 500;
            font-size: 1.1rem;

            &.btn-anterior {
                color: #3f51b5;
                justify-self: start;
            }

            &.btn-proximo {
                background: #3f51b5;
                border-color: #3f51b5;
                color: #fff;
                justify-self: end;
            }
        }
    }
}
</style>
