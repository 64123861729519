<template>
    <Dialog v-model:visible="open" modal :closable="false">
        <div class="flex flex-column align-items-center gap-5">
            <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 5rem; color: #3f51b5" />
            <i v-else-if="valido" class="pi pi-check-circle" style="font-size: 5rem; color: #4caf50" />
            <i v-else-if="!valido" class="pi pi-times-circle" style="font-size: 5rem; color: #f44336" />
            <span class="text-2xl text-center font-bold px-4">Por favor, aguarde enquanto seus dados são validados...</span>
        </div>
    </Dialog>
</template>

<script>
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutPrestador } from '@/services/auth';

export default {
    props: {
        formData: { type: Object, required: true }
    },
    data() {
        return {
            open: false,
            loading: false,
            valido: true
        };
    },
    methods: {
        async openDialogAndValidate() {
            this.open = true;
            this.loading = true;

            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).post('/cadastro-prestador/validar-dados-soc', this.formData);
                this.valido = data;
            } catch (error) {
                this.valido = false;
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/cadastro-prestador/');
                }
            }

            this.loading = false;

            await new Promise((resolve) =>
                setTimeout(() => {
                    resolve();
                }, 500)
            );

            this.open = false;
            return this.valido;
        }
    }
};
</script>
