<template>
    <div>
        <DataTable class="border-round-2xl border-1 border-bluegray-100 overflow-hidden" :value="configuracoesAmbiente.ambientes">
            <Column
                v-for="col of columns"
                :key="col.header"
                :field="col.field"
                :header="col.header"
                :export-header="col.header"
                header-class="font-semibold text-center text-bluegray-500"
                header-style="font-family: Poppins-Regular, sans-serif"
                :style="col.style"
            >
                <template #body="slotProps">
                    <component
                        :model-value="slotProps.data[slotProps.field]"
                        :is="col.component"
                        :options="procedimentos"
                        @update:model-value="(val) => handleSelecaoProcedimentos(val, slotProps.index, slotProps.field)"
                        display="chip"
                        optionLabel="descricao"
                        optionValue="descricao"
                        :key="procedimentos.length"
                        :filter="true"
                        :showClear="true"
                        multiple
                        :min="0"
                        :max="365"
                        class="h-3rem flex align-items-center w-full max-w-10rem md:max-w-19rem"
                        :class="{
                            'p-invalid':
                                v$.configuracoesAmbiente.ambientes.$errors[0]?.$response?.$errors?.[slotProps.index][col.field]?.[0]?.$message
                        }"
                    />
                </template>
            </Column>
            <Column :exportable="false" style="width: 55px" header-style="font-family: Poppins-Medium, sans-serif">
                <template #body="slotProps">
                    <Button
                        v-if="slotProps.index !== 0"
                        :class="{ 'p-button-rounded': !isMobileFormat, 'p-button-text': !isMobileFormat, 'p-button-outlined': isMobileFormat }"
                        style="color: #3f51b5"
                        icon="pi pi-trash"
                        :label="isMobileFormat ? 'Apagar' : ''"
                        @click="apagarAmbiente(slotProps.index)"
                    />
                </template>
            </Column>
        </DataTable>
        <Button
            label="Adicionar ambiente"
            icon="pi pi-plus"
            class="p-button p-button-text p-button-rounded add-btn w-fit mt-1"
            @click="adicionarAmbiente"
        />
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useWindowSize } from '@vueuse/core/index';
export default {
    emits: ['update:formData'],
    props: {
        formData: {
            type: Object,
            required: true
        },
        procedimentos: {
            type: Array,
            required: true
        }
    },
    setup() {
        const { width } = useWindowSize();
        return {
            width,
            v$: useVuelidate()
        };
    },
    data() {
        return {
            columns: [
                { field: 'nome', header: 'Nome do ambiente', component: 'InputText' },
                { field: 'exames', header: 'Exames realizados', component: 'MultiSelect', style: 'width: 35%' },
                { field: 'codigoAgendaSoc', header: 'Código agenda SOC', component: 'InputText', style: 'width: 15%' },
                { field: 'tempoAtendimento', header: 'Tempo de atendimento (minutos)', component: 'InputNumber', style: 'width: 15%' }
            ]
        };
    },
    computed: {
        isMobileFormat() {
            return this.width < 961;
        },
        configuracoesAmbiente: {
            get() {
                return this.formData;
            },
            set(value) {
                this.$emit('update:formData', value);
            }
        }
    },
    methods: {
        validar() {
            return this.v$.$validate();
        },
        adicionarAmbiente() {
            this.configuracoesAmbiente.ambientes.push({
                nome: '',
                exames: [],
                codigoAgendaSoc: '',
                tempoAtendimento: 0
            });
        },
        apagarAmbiente(indexAmbiente) {
            this.configuracoesAmbiente.ambientes.splice(indexAmbiente, 1);
        },
        tempoAtendimentoRequiredPersonalizado(value) {
            return value && value > 0;
        },
        handleSelecaoProcedimentos(value, indexAmbiente, field) {
            if (typeof value === 'string') {
                value = value.trim();
            }
            this.configuracoesAmbiente.ambientes[indexAmbiente][field] = value;

            if (!Array.isArray(value)) {
                return;
            }

            const examesSet = new Set();
            this.configuracoesAmbiente.ambientes.forEach((a) => {
                a.exames.forEach((exame) => {
                    if (examesSet.has(exame)) {
                        this.$toast.add({
                            severity: 'info',
                            summary: `O exame "${exame}" já foi selecionado em outro ambiente`,
                            life: 3000
                        });
                        this.configuracoesAmbiente.ambientes[indexAmbiente].exames = this.configuracoesAmbiente.ambientes[
                            indexAmbiente
                        ].exames.filter((a) => !a.includes(exame));
                    } else {
                        examesSet.add(exame);
                    }
                });
            });
        }
    },
    validations() {
        return {
            configuracoesAmbiente: {
                ambientes: {
                    $each: helpers.forEach({
                        nome: {
                            required: helpers.withMessage('Informe o nome do ambiente', required)
                        },
                        exames: {
                            required: helpers.withMessage('Selecione pelo menos um exame', required)
                        },
                        codigoAgendaSoc: {
                            required: helpers.withMessage('Informe o código da agenda SOC', required)
                        },
                        tempoAtendimento: {
                            tempoAtendimentoRequiredPersonalizado: helpers.withMessage(
                                'Informe o tempo de atendimento',
                                this.tempoAtendimentoRequiredPersonalizado
                            )
                        }
                    })
                }
            }
        };
    }
};
</script>

<style lang="scss" scoped>
.add-btn {
    font-size: 1.2rem;
    font-family: Poppins-Medium, sans-serif;
    color: #3f51b5;
}

:deep(.p-inputnumber-input) {
    width: 100%;
}
</style>
