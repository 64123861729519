<template>
    <TopBarPageContainer>
        <div class="conteudo text-bluegray-800 text-xl">
            <Image
                src="layout/images/duon_logo_azul.svg"
                alt="Logo formando a palavra Duon"
                width="105"
                class="logo flex justify-content-center align-items-center mb-5"
            />

            <span class="text-center text-5xl font-bold reduzido">Cadastro do prestador</span>
            <span v-if="!loadingNome" class="text-center font-bold reduzido">{{ nomePrestador }}</span>
            <Skeleton v-else width="30rem" />
            <span class="text-center reduzido">Abaixo os campos que precisam ser preenchidos para fazer seu processo de agendamento automático</span>

            <CadastroPrestadorStepper />
        </div>
    </TopBarPageContainer>
</template>

<script>
import TopBarPageContainer from '@/components/prestador/TopBarPageContainer.vue';
import CadastroPrestadorStepper from './components/CadastroPrestadorStepper.vue';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutPrestador } from '@/services/auth';

export default {
    components: { CadastroPrestadorStepper, TopBarPageContainer },
    data() {
        return {
            loadingNome: false,
            nomePrestador: ''
        };
    },
    async mounted() {
        this.loadingNome = true;
        try {
            const prestadorToken = getPrestadorToken();
            const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
            const { data } = await getClientBase(authToken).get('/cadastro-prestador/nome-prestador');
            this.nomePrestador = data;
        } catch (error) {
            if (error.response.data.statusCode === 401) {
                logoutPrestador();
                await this.$router.push('/cadastro-prestador/');
            }
            this.$toast.add({
                severity: 'error',
                summary: 'Erro ao processar sua solicitação',
                detail: error.response.data.message,
                life: 3000
            });
        }
        this.loadingNome = false;
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

.conteudo {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-family: Poppins-Regular, sans-serif;
    height: 100%;
}

.reduzido {
    max-width: 480px;
}
</style>
