<template>
    <div class="p-input-icon-right w-full relative">
        <i class="pi pi-search pr-2 text-bluegray-700" />
        <InputText
            v-model="procedimentoPesquisado"
            placeholder="Pesquisar procedimentos"
            class="p-inputtext-lg w-full h-4rem px-4 input text-bluegray-700"
            :class="{ 'p-invalid': validation.$invalid }"
            @focus="focarInput"
        />
        <small v-if="validation.$invalid || validation.$pending.$response" class="absolute top-100 left-0 p-error w-full">
            {{ validation.required.$message }}
        </small>
        <div
            v-if="podeAparecer"
            class="items-container animate__animated w-full bg-white absolute top-0 left-0 mt-8 border-round-2xl flex flex-column justify-content-between z-5 shadow-2"
            :class="{ animate__fadeIn: inputFocado, animate__fadeOut: !inputFocado }"
        >
            <div v-if="procedimentosFiltrados.length" class="h-18rem border-bottom-1 border-gray-100">
                <div
                    class="flex flex-column gap-3 p-4 pb-0 content overflow-y-auto h-18rem"
                    v-scroll="{ callback: carregarMaisDados, offset: '30px' }"
                >
                    <span v-for="(procedimento, index) in procedimentosFiltrados" :key="index" class="flex align-items-center gap-4">
                        <Checkbox :input-id="`check-${index}`" :value="procedimento" v-model="procedimentos" />
                        <label :for="`check-${index}`" class="text-bluegray-700 cursor-pointer">{{ procedimento.descricao }}</label>
                    </span>
                </div>
            </div>

            <div v-else class="p-4 h-full flex justify-content-center align-items-center border-bottom-1 border-gray-100 text-center">
                Não há procedimentos que correspondam a busca acima. Clique em "Adicionar procedimento" para adicionar como um novo procedimento.
            </div>

            <Button
                v-if="!procedimentosFiltrados.length"
                label="Adicionar procedimento"
                class="p-button-rounded p-button-text p-button-lg m-2 w-max"
                style="color: #3f51b5; font-family: Poppins-Medium, sans-serif"
                :loading="loadingScroll"
                @click="adicionarProcedimento"
            />
        </div>
        <Toast />
    </div>
</template>

<script>
import 'animate.css';
import { getClientBase } from '@/services/http';
import { getPrestadorToken } from '@/common/storage';
export default {
    emits: ['update:procedimentosSelecionados'],
    props: {
        procedimentosSelecionados: { type: Array, required: true },
        validation: { type: Object, required: true }
    },
    data() {
        return {
            procedimentoPesquisado: '',
            fakeApiProcedimentos: ['Clinico', 'Hemograma', 'Audiometrico', 'Sinético funcional'],
            inputFocado: false,
            podeAparecer: false,
            timeout: null,
            page: 1,
            limit: 20,
            procedimentosFiltrados: [],
            loadingScroll: false
        };
    },
    directives: {
        scroll: {
            mounted(el, binding) {
                const handleScroll = () => {
                    const container = el;
                    const offset = parseInt(binding.value.offset);
                    if (container.scrollTop + container.clientHeight >= container.scrollHeight - offset) {
                        binding.value.callback(); // Chamar o callback quando chegar ao final
                    }
                };

                el.addEventListener('scroll', handleScroll);

                return () => {
                    el.removeEventListener('scroll', handleScroll);
                };
            }
        }
    },
    watch: {
        procedimentoPesquisado() {
            this.page = 1;
            this.procedimentosFiltrados = [];
            this.carregarDados();
        },
        procedimentosFiltrados: {
            handler(val, oldVal) {
                if (val.length !== oldVal.length) {
                    this.procedimentosFiltrados = val.map((p) => {
                        const procedimento = this.procedimentosSelecionados.find((q) => q.descricao === p.descricao);
                        if (procedimento) return procedimento;
                        return p;
                    });
                }
            },
            deep: true
        }
    },
    computed: {
        procedimentos: {
            get() {
                return this.procedimentosSelecionados;
            },
            set(value) {
                this.$emit('update:procedimentosSelecionados', value);
            }
        }
    },
    methods: {
        focarInput() {
            clearInterval(this.timeout);
            this.podeAparecer = true;
            this.inputFocado = true;
        },
        desfocarInput() {
            this.inputFocado = false;
        },
        handleClickFora(event) {
            if (this.inputFocado && !this.$el.contains(event.target)) {
                this.desfocarInput();
                this.timeout = setTimeout(() => {
                    this.podeAparecer = false;
                }, 300);
            }
        },
        adicionarProcedimento() {
            if (
                this.procedimentoPesquisado.trim() &&
                !this.procedimentos.some((procedimento) => procedimento.descricao === this.procedimentoPesquisado)
            ) {
                const procedimentoASerAdd = {
                    descricao: this.procedimentoPesquisado,
                    antecedencia: 0,
                    tempoProcedimento: 0
                };
                this.procedimentos = [...this.procedimentos, procedimentoASerAdd];
                this.procedimentoPesquisado = '';
            }
        },
        async carregarDados() {
            const { page, limit, procedimentoPesquisado } = this;
            const prestadorToken = getPrestadorToken();
            const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
            const { data } = await getClientBase(authToken).get('cadastro-prestador/exames', {
                params: {
                    page,
                    limit,
                    filter: procedimentoPesquisado
                }
            });
            this.procedimentosFiltrados = [
                ...this.procedimentosFiltrados,
                ...data.map((procedimento) => {
                    return { ...procedimento, antecedencia: 0, tempoProcedimento: 0 };
                })
            ];
        },
        async carregarMaisDados() {
            if (this.loadingScroll) {
                return;
            }

            try {
                this.loadingScroll = true;
                this.page++;
                await this.carregarDados();
            } catch (error) {
                this.page--;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Não foi possível carregar mais dados',
                    life: 3000
                });
            } finally {
                this.loadingScroll = false;
            }
        }
    },
    async beforeMount() {
        await this.carregarDados();
    },
    mounted() {
        document.addEventListener('click', this.handleClickFora);
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickFora);
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

:deep(.input) {
    font-family: Poppins-Regular, sans-serif;
}

.items-container {
    --animate-duration: 0.3s;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #3f51b5aa;
    border-radius: 6px;
}

::-webkit-scrollbar-button {
    display: none;
}
</style>
