<template>
    <div>
        <DataTable class="border-round-2xl border-1 border-bluegray-100 overflow-hidden" :value="procedimentos" dataKey="procedimento">
            <Column
                v-for="col of columns"
                :key="col.field"
                :field="col.field"
                :header="isMobileFormat ? col.header : ''"
                :export-header="col.header"
                header-class="font-semibold text-bluegray-500"
                header-style="font-family: Poppins-Regular, sans-serif"
                :style="{ width: getWidth(col.field) }"
            >
                <template #body="slotProps">
                    <span v-if="slotProps.field === 'descricao'" class="font-semibold text-right" style="font-family: Poppins-Medium, sans-serif">{{
                        slotProps.data[slotProps.field]
                    }}</span>
                    <span v-if="slotProps.field === 'tempoProcedimento'">
                        <InputNumber
                            v-model="slotProps.data[slotProps.field]"
                            :min="0"
                            :max="1440"
                            class="flex justify-content-end"
                            input-class="w-4rem"
                            :class="{ 'p-invalid': slotProps.data[slotProps.field] < 0 }"
                        />
                    </span>
                    <span v-if="slotProps.field === 'antecedencia'">
                        <InputNumber
                            v-model="slotProps.data[slotProps.field]"
                            :min="0"
                            :max="365"
                            class="flex justify-content-end"
                            input-class="w-4rem"
                            :class="{ 'p-invalid': slotProps.data[slotProps.field] < 0 }"
                        />
                    </span>
                </template>
                <template #header="slotProps">
                    <span class="flex align-items-center">
                        <span>{{ col.header }}</span>
                        <i
                            v-if="slotProps.column.props.field === 'antecedencia'"
                            v-tooltip.top="{
                                value: 'Dias de antecedência do exame em relação ao exame clínico',
                                pt: {
                                    text: 'w-15rem'
                                }
                            }"
                            class="pi pi-info-circle"
                            style="font-size: 1.2rem; margin-left: 0.5rem; color: #3f51b5"
                        />
                        <i
                            v-if="slotProps.column.props.field === 'tempoProcedimento'"
                            v-tooltip.top="{
                                value: 'Tempo definido em minutos do procedimento',
                                pt: {
                                    text: 'w-22rem'
                                }
                            }"
                            class="pi pi-info-circle"
                            style="font-size: 1.2rem; margin-left: 0.5rem; color: #3f51b5"
                        />
                    </span>
                </template>
            </Column>
            <Column :exportable="false" style="width: 35px" header-style="font-family: Poppins-Medium, sans-serif">
                <template #body="slotProps">
                    <Button
                        :class="{ 'p-button-rounded': !isMobileFormat, 'p-button-text': !isMobileFormat, 'p-button-outlined': isMobileFormat }"
                        style="color: #3f51b5"
                        icon="pi pi-trash"
                        :label="isMobileFormat ? 'Apagar' : ''"
                        @click="apagarProcedimento(slotProps.data)"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { useWindowSize } from '@vueuse/core/index';

export default {
    emits: ['update:procedimentosSelecionados'],
    props: {
        procedimentosSelecionados: { type: Array, required: true }
    },
    setup() {
        const { width } = useWindowSize();

        return {
            width
        };
    },
    data() {
        return {
            columns: [
                { field: 'descricao', header: 'Procedimento' },
                { field: 'tempoProcedimento', header: 'Tempo do procedimento' },
                { field: 'antecedencia', header: 'Dias de antecedência' }
            ]
        };
    },
    computed: {
        isMobileFormat() {
            return this.width < 961;
        },
        procedimentos: {
            get() {
                return this.procedimentosSelecionados;
            },
            set(value) {
                this.$emit('update:procedimentosSelecionados', value);
            }
        }
    },
    methods: {
        apagarProcedimento(procedimento) {
            this.procedimentos = this.procedimentos.filter((p) => p.descricao !== procedimento.descricao);
        },
        getWidth(field) {
            const defaultWidth = '60%';
            const specificWidths = {
                antecedencia: '20%',
                tempoProcedimento: '20%'
            };

            return specificWidths[field] || defaultWidth;
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}

:deep(.p-column-title) {
    font-family: Poppins-Regular, sans-serif !important;
    color: var(--bluegray-500) !important;
}
</style>
