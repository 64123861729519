<template>
    <Dialog v-model:visible="open" modal class="w-30rem max-w-full">
        <template #header>
            <span class="text-3xl font-bold poppins"> Erro ao salvar </span>
        </template>
        <template #default>
            <div class="poppins-medium text-xl">É necessário que os exames que foram registrados estejam vinculados a algum ambiente específico.</div>
        </template>
        <template #footer>
            <Button label="Fechar" :loading="loading" class="p-button-rounded confirmar-btn poppins text-xl" @click="closeDialog" />
        </template>
    </Dialog>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            loading: false
        };
    },
    methods: {
        openDialog() {
            this.open = true;
        },
        closeDialog() {
            this.open = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.poppins {
    font-family: Poppins-Regular, sans-serif;
}

.poppins-medium {
    font-family: Poppins-Medium, sans-serif;
}

.confirmar-btn {
    background-color: #3f51b5;
    color: #fff;
    border-color: #3f51b5;

    &:hover {
        background-color: #3f51b5;
        color: #fff;
        border-color: #3f51b5;
    }
}
</style>
