<template>
    <Dialog v-model:visible="open" closable modal class="w-30rem max-w-full">
        <template #header>
            <span class="text-3xl font-bold poppins"> Salvar informações </span>
        </template>
        <template #default>
            <div class="poppins-medium text-xl">
                Para que o processo seja automatizado, as informações da sua clínica devem estar preenchidas corretamente.
            </div>
        </template>
        <template #footer>
            <Button label="Cancelar" class="p-button-outlined p-button-rounded cancelar-btn poppins text-xl" @click="closeDialog" />
            <Button label="Salvar" :loading="loading" class="p-button-rounded salvar-btn poppins text-xl" @click="cadastrarPrestador" />
        </template>
    </Dialog>
</template>

<script>
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';

export default {
    emits: ['onSalvarPrestador'],
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            open: false,
            loading: false
        };
    },
    methods: {
        openDialog() {
            this.open = true;
        },
        closeDialog() {
            this.open = false;
        },
        async cadastrarPrestador() {
            this.loading = true;
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const prestadorSalvo = (await getClientBase(authToken).post('/cadastro-prestador/salvar-prestador', this.formData)).data;
                if (prestadorSalvo) {
                    this.$emit('onSalvarPrestador');
                }
            } catch (error) {
                console.log(error);
            }
            this.loading = false;

            this.closeDialog();
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.poppins {
    font-family: Poppins-Regular, sans-serif;
}

.poppins-medium {
    font-family: Poppins-Medium, sans-serif;
}

.cancelar-btn {
    color: #3f51b5;
    border-color: #cbd5e1;

    &:hover {
        color: #3f51b5 !important;
        border-color: #cbd5e1 !important;
    }
}

.salvar-btn {
    background-color: #3f51b5;
    color: #fff;
    border-color: #3f51b5;

    &:hover {
        background-color: #3f51b5;
        color: #fff;
        border-color: #3f51b5;
    }
}
</style>
