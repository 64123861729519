<template>
    <div>
        <div class="font-bold text-2xl">Procedimentos realizados</div>
        <div class="text-bluegray-700 font-medium">
            Os procedimentos possuem a nomenclatura do e-Social. Por favor, cadastre apenas procedimentos utilizados para reservar horários.
        </div>

        <InputBuscaOuAddProcedimentos
            v-model:procedimentosSelecionados="procedimentosSelecionados"
            class="my-4"
            :validation="v$.procedimentosSelecionados"
        />

        <TabelaProcedimentosSelecionados v-if="procedimentosSelecionados.length" v-model:procedimentosSelecionados="procedimentosSelecionados" />
    </div>
</template>

<script>
import InputBuscaOuAddProcedimentos from './InputBuscaOuAddProcedimentos.vue';
import TabelaProcedimentosSelecionados from './TabelaProcedimentosSelecionados.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
export default {
    emits: ['update:formData'],
    components: { InputBuscaOuAddProcedimentos, TabelaProcedimentosSelecionados },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    props: {
        formData: {
            type: Object,
            required: true
        }
    },
    computed: {
        procedimentosSelecionados: {
            get() {
                return this.formData;
            },
            set(value) {
                this.$emit('update:formData', value);
            }
        }
    },
    methods: {
        tratarProcedimentosSelecionados() {
            this.procedimentosSelecionados = this.procedimentosSelecionados.map((procedimento) => {
                return {
                    ...procedimento,
                    antecedencia: procedimento.antecedencia ? procedimento.antecedencia : 0,
                    tempoProcedimento: procedimento.tempoProcedimento ? procedimento.tempoProcedimento : 0
                };
            });
        },
        validar() {
            this.tratarProcedimentosSelecionados();
            return this.v$.$validate();
        }
    },
    validations() {
        return {
            procedimentosSelecionados: {
                required: helpers.withMessage('Selecione pelo menos um procedimento', required),
                $lazy: true
            }
        };
    }
};
</script>
